import React from "react"
import Layout from "../../components/layout"

import newsPostStyles from "../../styles/templates/news-post.module.css"

import corporate1 from "../../images/news/article-5/TED1.jpg"
import corporate2 from "../../images/news/article-5/TED5.jpg"
import corporate3 from "../../images/news/article-5/TED6.jpg"

const Article5Page = () => {
    return (
        <Layout location="news">
        <div className={ newsPostStyles.article }>
            <h1>CORPORATE EVENTS</h1>
            <h3>November 26, 2020</h3>
            <div className={ newsPostStyles.imageRow }>
                <img src={corporate1} alt=""/>
                <img src={corporate2} alt=""/>
                <img src={corporate3} alt=""/>
            </div>
            <p>TeamED is a reliable name in the corporate scene, for both business and social events of companies big and small, local and foreign, MNCs and SMEs, providing enterprises and individuals a professional level of event management service (from conceptualization to execution) that is adjudged creative, proactive and value-filled.</p>
            <button onClick={() => {
                window.history.back()
            }}>Go Back</button>
        </div>
        </Layout>
    )
}

export default Article5Page